<script>
import { Bar } from "vue-chartjs";
import {
  getStyle,
  hexToRgba,
} from "@coreui/coreui-pro/dist/js/coreui-utilities";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { random } from "../../shared/utils/generators-helper";

export default {
  extends: Bar,
  props: { data: { type: Array }, maxData: { type: Number } },
  watch: {
    data() {
      const brandSuccess = getStyle("--success") || "#4dbd74";
      const brandInfo = getStyle("--info") || "#20a8d8";
      const brandDanger = getStyle("--danger") || "#f86c6b";

      const mainDataset = this.data.map((p) => p.totalViewCount);
      const labels = this.data.map((prop) => prop.categoryNamne);
      this.renderChart(
        {
          labels: labels,
          datasets: [
            {
              label: this.$t("totalView"),
              backgroundColor: hexToRgba(brandDanger, 30),
              borderColor: brandDanger,
              pointHoverBackgroundColor: "#fff",
              borderWidth: 2,
              data: mainDataset,
            },
          ],
        },
        {
          tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: "single",
            position: "nearest",
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                return {
                  backgroundColor:
                    chart.data.datasets[tooltipItem.datasetIndex].borderColor,
                };
              },
            },
          },
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 5,
                  stepSize: Math.ceil((this.maxData * 1.1) / 5),
                  max: Math.round(this.maxData * 1.1),
                },
                gridLines: {
                  display: true,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
              hoverBorderWidth: 3,
            },
          },
        }
      );
    },
  },
};
</script>
