<template>
  <div class="animated fadeIn">
    <b-row>
      <div class="col-sm-3 col-lg-3">
        <div class="card blue text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.totalUserCount }}
                <i class="fa fa-users h2 card-icon" />
              </div>
              <div>{{ $t("totalUserCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card red-light text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.totalNewsReadCounter }}
                <i class="fa fa-newspaper-o h2 card-icon" />
              </div>
              <div>{{ $t("totalNewsReadCounter") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card green text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.todayNewsReadCounter }}
                <i class="fa fa-sun-o h2 card-icon" />
              </div>
              <div>{{ $t("todayNewsReadCounter") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card blue-light text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.totalNewsCount }}
                <i class="fa fa-paperclip h2 card-icon" />
              </div>
              <div>{{ $t("totalNewsCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card red text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.todayCreatedNewsCount }}
                <i class="fa fa-file-text h2 card-icon" />
              </div>
              <div>{{ $t("todayCreatedNewsCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card dark text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.totalPhotoGalleryCount }}
                <i class="fa fa-image h2 card-icon" />
              </div>
              <div>{{ $t("totalPhotoGaleryCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card red-light text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.todayCreatedPhotoGalleryCount }}
                <i class="fa fa-file-text h2 card-icon" />
              </div>
              <div>{{ $t("todayCreatedPhotoGaleryCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card blue text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.todayCreatedVideoGalleryCount }}
                <i class="fa fa-camera-retro h2 card-icon" />
              </div>
              <div>{{ $t("todayCreatedVideoGaleryCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card green text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.totalCommentCount }}
                <i class="fa fa-comments h2 card-icon" />
              </div>
              <div>{{ $t("totalCommentCount") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3 col-lg-3">
        <div class="card blue-light text-white">
          <div class="card-body pb-4 d-flex justify-content-between">
            <div>
              <div class="text-value-lg">
                {{ dashboard.todayCreatedCommentCount }}
                <i class="fa fa-commenting h2 card-icon" />
              </div>
              <div>{{ $t("todayCreatedCommentCount") }}</div>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col sm="5">
              <h4 id="traffic" class="card-title mb-0">
                {{ $t("viewerStatistics") }}
              </h4>
            </b-col>
            <b-col sm="7" class="d-none d-md-block">
              <b-button-toolbar
                class="float-right"
                aria-label="Toolbar with buttons group"
              >
                <b-form-radio-group
                  class="mr-3"
                  id="radiosBtn"
                  buttons
                  button-variant="outline-secondary"
                  v-model="chartPeriod"
                  name="radiosBtn"
                >
                  <b-form-radio
                    v-model="chartPeriod"
                    class="mx-0"
                    value="week"
                    >{{ $t("week") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="chartPeriod"
                    class="mx-0"
                    value="1month"
                    >{{ $t("1month") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="chartPeriod"
                    class="mx-0"
                    value="3month"
                    >{{ $t("3month") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="chartPeriod"
                    class="mx-0"
                    value="6month"
                    >{{ $t("6month") }}</b-form-radio
                  >
                  <b-form-radio
                    v-model="chartPeriod"
                    class="mx-0"
                    value="year"
                    >{{ $t("year") }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <main-chart-example
            chartId="main-chart-01"
            class="chart-wrapper"
            :data="chartData"
            :maxData="chartMaxBound"
            style="height: 300px; margin-top: 40px"
            :height="300"
          ></main-chart-example>
          <div slot="footer">
            <b-row class="text-center">
              <b-col
                class="mb-sm-2 mb-0"
                v-bind:key="item.categoryNamne"
                v-for="item in chartData"
              >
                <div class="text-muted">{{ item.categoryNamne }}</div>
                <strong
                  >{{ item.totalViewCount }} ({{ item.percentage }}%)</strong
                >
                <b-progress
                  height="{}"
                  class="progress-xs mt-2"
                  :precision="1"
                  variant="success"
                  :value="item.percentage"
                ></b-progress>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <h4 id="traffic" class="card-title mb-0">
            {{ $t("topCommentedNews") }}
          </h4>
          <b-card-body class="topCommentCard">
            <v-client-table
              :columns="columns"
              :data="list"
              :options="options"
              id="dataTable"
              class="gridDetail"
            >
              <template slot="newsTitle" slot-scope="props">
                <div
                  @click="openNewsComment(props.row.newsId)"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  {{ props.row.title }}
                </div>
              </template>
              <template slot="rank" slot-scope="props">
                {{ list.findIndex((p) => p.newsId == props.row.newsId) + 1 }}
              </template>
              <template slot="totalView" slot-scope="props">
                {{ props.row.totalViewCount }}
              </template>
              <template slot="totalComment" slot-scope="props">
                {{ props.row.commentsCount }}
              </template>
            </v-client-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import http from "../shared/http";
import { ClientTable } from "vue-tables-2";
import gridOptions from "../shared/grid-options";
import { random } from "../shared/utils/generators-helper";
import MainChartExample from "../widgets/dashboard/MainChartExample";
import dateHelper from "../shared/date-helper";
import jwtHelper from "../shared/jwt-helper";
export default {
  name: "dashboard",
  components: { MainChartExample },
  data() {
    return {
      list: [],
      chartData: null,
      chartMaxBound: 0,
      chartPeriod: "week",
      columns: ["rank", "newsTitle", "totalView", "totalComment"],
      options: {
        ...gridOptions.getAll(),
        filterable: false,
        sortable: "",
        orderBy: {
          column: "id",
          ascending: false,
        },
        headings: {
          rank: "#",
          newsTitle: this.$t("newsTitle"),
          totalView: this.$t("newsTitle"),
          totalComment: this.$t("newsTitle"),
        },
      },
      dashboard: {
        totalUserCount: 0,
        totalNewsReadCounter: 0,
        todayNewsReadCounter: 0,
        totalNewsCount: 0,
        todayCreatedNewsCount: 0,
        totalPhotoGalleryCount: 0,
        todayCreatedPhotoGalleryCount: 0,
        totalVideoGalleryCount: 0,
        todayCreatedVideoGalleryCount: 0,
        totalCommentCount: 0,
        todayCreatedCommentCount: 0,
      },
    };
  },
  methods: {
    openNewsComment(newsId) {
      this.$router.push("/news-comments/" + newsId);
    },
    getList() {
      let path = "/dashboards/getdashboardtopcommentnews?limit=10";
      http.get(path).then((response) => {
        if (response) this.list = response;
      });
    },
    getPeriodDate(period) {
      switch (period) {
        case "week":
          return dateHelper.addDay(null, -7);
          break;
        case "1month":
          return dateHelper.addDay(null, -30);
          break;
        case "3month":
          return dateHelper.addDay(null, -90);
          break;
        case "6month":
          return dateHelper.addDay(null, -180);
          break;
        case "year":
          return dateHelper.addDay(null, -365);
          break;
        default:
          return dateHelper.addDay(null, -7);
          break;
      }
    },
    getChartData(period) {
      const startDate = this.getPeriodDate(period);
      let path = `/dashboards/getdashboardchartdata?period=${startDate}`;
      http.get(path).then((response) => {
        if (response) {
          this.chartData = response;
          let total = 0;
          let max = 0;
          for (let i = 0; i < this.chartData.length; i++) {
            // TEST IF THERE IS NO DATA
            // this.chartData[i].totalViewCount = random(10, 100);
            total = total + this.chartData[i].totalViewCount;
            if (max < this.chartData[i].totalViewCount)
              max = this.chartData[i].totalViewCount;
          }
          this.chartMaxBound = max;
          for (let i = 0; i < this.chartData.length; i++) {
            if (total > 0) {
              this.chartData[i].percentage = (
                (this.chartData[i].totalViewCount / total) *
                100
              ).toFixed(2);
            } else {
              this.chartData[i].percentage = 0;
            }
          }
        }
      });
    },
    getDashboardStatistic() {
      let path = "dashboards/getdashboardstatistics";
      http.get(path).then((data) => {
        if (data) {
          this.dashboard = data;
        }
      });
    },
    getUserClaims() {
      const userId = jwtHelper.getUserId();
      http
        .get(`UserOperationClaims/getbyuserid?userId=${userId}`, null, false)
        .then((response) => {
          const ids = response.map((p) => p.operationClaimId);
          if (
            JSON.stringify(ids) !=
            JSON.stringify(this.$store.state.userClaims.get())
          ) {
            this.$store.state.userClaims.set(
              response.map((p) => p.operationClaimId)
            );
            //window.location.reload();
          }
        });
    },
  },
  created() {
    this.getUserClaims();
    this.getDashboardStatistic();
    this.getList();
    this.getChartData("week");
  },
  watch: {
    chartPeriod() {
      this.getChartData(this.chartPeriod);
    },
  },
};
</script>
<style scoped>
.icon-shape {
  padding: 12px;
  text-align: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}
.icon {
  width: 3rem;
  height: 3rem;
}
.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c, #f56036) !important;
}
.card-profile-image img,
.shadow {
  -webkit-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.fa {
  font-size: 22px;
}
.topCommentCard {
  padding: 1.5rem 0rem 0rem 0rem !important;
}
.card-icon {
  position: absolute;
  right: 10px;
  font-size: 45px;
}
.text-value-lg {
  margin-bottom: 12px;
}
.blue {
  background-color: #321fdb !important;
}
.blue-light {
  background-color: #39f !important;
}
.red-light {
  background-color: #f9b115 !important;
}
.red {
  background-color: #e55353 !important;
}
.green {
  background-color: #2eb85c !important;
}
.dark {
  background-color: #636f83 !important;
}
.gray {
  background-color: #ced2d8 !important;
}
</style>